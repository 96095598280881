/**
 * @owner @Appboy/intelligence
 */

import type { I18nT } from "@lib/dashboardI18n";

export type CopywriterSettingsLabelsTrans = I18nT<"copywriter-settings-type">;

const copywriterSettingsTypeLabelsGetter = (t: I18nT<"copywriter-settings-type">) => ({
  None: t("default-copywriter-settings-type-label", "None"),
  message_set: t("message-set", "Message Set"),
  brand_guidelines: t("brand-guidelines", "Brand Guidelines"),
});

export type CopywriterSettingsTypeLabelsOptions = keyof ReturnType<typeof copywriterSettingsTypeLabelsGetter>;

export default copywriterSettingsTypeLabelsGetter;
