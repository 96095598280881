/**
 * @owner @Appboy/messaging-and-automation
 */

import sharedConstants from "@lib/shared_constants.json";
import featureFlipper from "lib/util/feature-flipper";
import localizedLabels from "@lib/localizedLabelsProvider";
import { I18n } from "@lib/i18n";

const { t } = I18n.namespace("notification-constants");

const notificationLabels = localizedLabels.getNotificationLabels(t);

const NOTIFICATION_IDS = {
  PAYMENT_AND_BILLING: sharedConstants.NOTIFICATIONS.PAYMENT_AND_BILLING.ID,
  AWS_CREDENTIALS: sharedConstants.NOTIFICATIONS.AWS_CREDENTIALS.ID,
  MARKETING_CAMPAIGN_DELIVERY: sharedConstants.NOTIFICATIONS.MARKETING_CAMPAIGN_DELIVERY.ID,
  CAMPAIGN_LIMIT_MET: sharedConstants.NOTIFICATIONS.CAMPAIGN_LIMIT_MET.ID,
  SCHEDULED_CAMPAIGN_FINISHED_SENDING: sharedConstants.NOTIFICATIONS.SCHEDULED_CAMPAIGN_FINISHED_SENDING.ID,
  PUSH_CREDENTIALS: sharedConstants.NOTIFICATIONS.PUSH_CREDENTIALS.ID,
  NEWS_FEED_CARD_LIVE: sharedConstants.NOTIFICATIONS.NEWS_FEED_CARD_LIVE.ID,
  WEEKLY_ANALYTICS_REPORT: sharedConstants.NOTIFICATIONS.WEEKLY_ANALYTICS_REPORT.ID,
  MARKETING_OBJECT_UPDATED: sharedConstants.NOTIFICATIONS.MARKETING_OBJECT_UPDATED.ID,
  CAMPAIGN_INTERACTION_EXPIRATION: sharedConstants.NOTIFICATIONS.CAMPAIGN_INTERACTION_EXPIRATION.ID,
  WORKFLOW_INTERACTION_EXPIRATION: sharedConstants.NOTIFICATIONS.WORKFLOW_INTERACTION_EXPIRATION.ID,
  AZURE_CREDENTIALS: sharedConstants.NOTIFICATIONS.AZURE_CREDENTIALS.ID,
  GCS_CREDENTIALS: sharedConstants.NOTIFICATIONS.GCS_CREDENTIALS.ID,
  STALE_CAMPAIGN_STOPPED: sharedConstants.NOTIFICATIONS.STALE_CAMPAIGN_STOPPED.ID,
  STALE_CANVAS_STOPPED: sharedConstants.NOTIFICATIONS.STALE_CANVAS_STOPPED.ID,
  WEBHOOK_ERRORS: sharedConstants.NOTIFICATIONS.WEBHOOK_ERRORS.ID,
  CANVAS_COMMENTS: sharedConstants.NOTIFICATIONS.CANVAS_COMMENTS.ID,
  CONNECTED_CONTENT_ERRORS: sharedConstants.NOTIFICATIONS.CONNECTED_CONTENT_ERRORS.ID,
};

const NOTIFICATIONS_WITH_ALL_TAGS_ALLOWED = [
  sharedConstants.NOTIFICATIONS.PUSH_CREDENTIALS.ID,
  sharedConstants.NOTIFICATIONS.WEEKLY_ANALYTICS_REPORT.ID,
];

// the fanciness below allows us to get autocompletion and type safety on dereferences of IDS_TO_TYPES
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

type IdMap = UnionToIntersection<
  {
    [ID in keyof typeof sharedConstants.NOTIFICATIONS]: {
      [P in (typeof sharedConstants.NOTIFICATIONS)[ID]["ID"]]: (typeof sharedConstants.NOTIFICATIONS)[ID]["TYPE"];
    };
  }[keyof typeof sharedConstants.NOTIFICATIONS]
>;

const IDS_TO_TYPES: IdMap = Object.values(sharedConstants.NOTIFICATIONS).reduce((accum, v) => {
  accum[v.ID] = notificationLabels(v.TYPE);
  return accum;
}, {});

const IDS_TO_HELPER_TEXT = {
  [NOTIFICATION_IDS.PAYMENT_AND_BILLING]: t(
    "helpertext-aws-payment-and-billing",
    "Notifies recipients when there is a problem with your payment method and when your account subscription changes."
  ),
  [NOTIFICATION_IDS.AWS_CREDENTIALS]: t(
    "helpertext-aws-credentials",
    "Notifies recipients when Braze receives an error while attempting to use your Amazon Web Services credentials for a data export."
  ),
  [NOTIFICATION_IDS.MARKETING_CAMPAIGN_DELIVERY]: t(
    "helpertext-market-campaign-delivery",
    "Notifies recipients when scheduled campaigns begin sending or when scheduled campaigns attempted to send, but had no eligible users to send to."
  ),
  [NOTIFICATION_IDS.CAMPAIGN_LIMIT_MET]: t(
    "helpertext-campaign-limit-met",
    "Notifies recipients when the limit for a recurring scheduled campaign has been reached."
  ),
  [NOTIFICATION_IDS.SCHEDULED_CAMPAIGN_FINISHED_SENDING]: t(
    "helpertext-campaign-finished-sending",
    "Notifies recipients when a scheduled campaign has finished sending."
  ),
  [NOTIFICATION_IDS.PUSH_CREDENTIALS]: t(
    "helpertext-push-credentials",
    "Notifies recipients when an app''s push credentials are invalid and when an app''s push credentials are expiring soon."
  ),
  [NOTIFICATION_IDS.NEWS_FEED_CARD_LIVE]: t(
    "helpertext-news-feed-card-live",
    "Notifies recipients when Newsfeed cards are scheduled or published."
  ),
  [NOTIFICATION_IDS.WEEKLY_ANALYTICS_REPORT]: t(
    "helpertext-weekly-analytics-report",
    "Sends a summary of the past week's workspace activity to recipients every Monday. Recipients receive a summary for each workspace that they belong to."
  ),
  [NOTIFICATION_IDS.CAMPAIGN_INTERACTION_EXPIRATION]: t(
    "helpertext-interaction-expiration",
    "Notifies recipients about any Campaign that is due for campaign interaction data expiration, along with any information about Segments, Campaigns, or Canvases that reference it in a retargeting filter and were used to send a message in the previous 30 days."
  ),
  [NOTIFICATION_IDS.WORKFLOW_INTERACTION_EXPIRATION]: t(
    "helpertext-workflow-interaction-expiration",
    "Notifies recipients about any Canvas that is due for Canvas interaction data expiration, along with any information about Segments, Campaigns, or Canvases that reference it in a retargeting filter and were used to send a message in the previous 30 days."
  ),
  [NOTIFICATION_IDS.STALE_CAMPAIGN_STOPPED]: t(
    "helpertext-campaign-auto-stopped",
    "Notifies recipients when Braze has stopped a campaign."
  ),
  [NOTIFICATION_IDS.STALE_CANVAS_STOPPED]: t(
    "helpertext-canvas-auto-stopped",
    "Notifies recipients when Braze has stopped a Canvas."
  ),
  [NOTIFICATION_IDS.CANVAS_COMMENTS]: t(
    "helpertext-canvas-comments",
    "Notifies recipients when a Canvas has new comments."
  ),
  [NOTIFICATION_IDS.MARKETING_OBJECT_UPDATED]: featureFlipper.featureOn(
    "send_creator_marketing_object_updated_notification"
  )
    ? t(
        "notify-recipients-and-creator-when-objects-reactivated",
        "Notifies campaign/canvas creator and recipients when an active campaign/canvas is updated or deactivated, when an inactive campaign/canvas is reactivated and when drafts are launched."
      )
    : t(
        "notify-recipients-only-when-objects-reactivated",
        "Notifies recipients when an active campaign/canvas is updated or deactivated, when an inactive campaign/canvas is reactivated and when drafts are launched."
      ),
  [NOTIFICATION_IDS.WEBHOOK_ERRORS]: t(
    "helpertext-webhook-errors",
    "Notifies recipients when a webhook endpoint has errors."
  ),
  [NOTIFICATION_IDS.CONNECTED_CONTENT_ERRORS]: t(
    "helpertext-connected-content-errors",
    "Notifies recipients when a Connected Content endpoint has errors."
  ),
};

const IDS_TO_NAMES = {
  [NOTIFICATION_IDS.PAYMENT_AND_BILLING]: t("payment-and-billing-name", "Payment and Billing"),
  [NOTIFICATION_IDS.AWS_CREDENTIALS]: t("aws-credentials-name", "AWS Credential Errors"),
  [NOTIFICATION_IDS.MARKETING_CAMPAIGN_DELIVERY]: t(
    "marketing-campaign-delivery-name",
    "Scheduled Campaign Sent/Not Sent"
  ),
  [NOTIFICATION_IDS.CAMPAIGN_LIMIT_MET]: t("campaign-limit-met-name", "Scheduled Campaign Limit Met"),
  [NOTIFICATION_IDS.SCHEDULED_CAMPAIGN_FINISHED_SENDING]: t(
    "scheduled-campaign-finished-sending-name",
    "Scheduled Campaign Finished Sending"
  ),
  [NOTIFICATION_IDS.PUSH_CREDENTIALS]: t("push-credentials-name", "Push Credential Errors"),
  [NOTIFICATION_IDS.NEWS_FEED_CARD_LIVE]: t("news-feed-card-live-name", "Newsfeed Card Published/Live"),
  [NOTIFICATION_IDS.WEEKLY_ANALYTICS_REPORT]: t("weekly-analytics-report-name", "Weekly Analytics Report"),
  [NOTIFICATION_IDS.MARKETING_OBJECT_UPDATED]: t("marketing-object-updated-name", "Campaign/Canvas Updated"),
  [NOTIFICATION_IDS.AZURE_CREDENTIALS]: t("azure-credentials-name", "Invalid Azure Credentials"),
  [NOTIFICATION_IDS.GCS_CREDENTIALS]: t("gcs-credentials-name", "Invalid GCS Credentials"),
  [NOTIFICATION_IDS.CAMPAIGN_INTERACTION_EXPIRATION]: t(
    "campaign-interaction-expiration-name",
    "Campaign Interaction Expiration"
  ),
  [NOTIFICATION_IDS.WORKFLOW_INTERACTION_EXPIRATION]: t(
    "workflow-interaction-expiration-name",
    "Canvas Interaction Expiration"
  ),
  [NOTIFICATION_IDS.STALE_CAMPAIGN_STOPPED]: t("campaign-auto-stopped", "Campaign Automatically Stopped"),
  [NOTIFICATION_IDS.STALE_CANVAS_STOPPED]: t("canvas-auto-stopped", "Canvas Automatically Stopped"),
  [NOTIFICATION_IDS.CANVAS_COMMENTS]: t("canvas-comments", "Comments within Canvases"),
  [NOTIFICATION_IDS.WEBHOOK_ERRORS]: t("webhook-errors", "Webhook Errors"),
  [NOTIFICATION_IDS.CONNECTED_CONTENT_ERRORS]: t("connected-content-errors", "Connected Content Errors"),
};

const NOTIFICTION_CATEGORIES_BASE = {
  [t("account-administrator", "Account Administration")]: [NOTIFICATION_IDS.AWS_CREDENTIALS],
  [t("partner-integration", "Partner Integrations")]: [NOTIFICATION_IDS.AWS_CREDENTIALS],
  [t("canvas-and-campaigns", "Canvas & Campaigns")]: [
    NOTIFICATION_IDS.MARKETING_CAMPAIGN_DELIVERY,
    NOTIFICATION_IDS.CAMPAIGN_LIMIT_MET,
    NOTIFICATION_IDS.SCHEDULED_CAMPAIGN_FINISHED_SENDING,
    NOTIFICATION_IDS.MARKETING_OBJECT_UPDATED,
    NOTIFICATION_IDS.CAMPAIGN_INTERACTION_EXPIRATION,
    NOTIFICATION_IDS.WORKFLOW_INTERACTION_EXPIRATION,
    featureFlipper.featureOn("push") && NOTIFICATION_IDS.PUSH_CREDENTIALS,
    NOTIFICATION_IDS.STALE_CAMPAIGN_STOPPED,
    featureFlipper.featureOn("canvas_auto_stop_r0") && NOTIFICATION_IDS.STALE_CANVAS_STOPPED,
    featureFlipper.featureOn("webhook_connected_content_alerts") &&
      featureFlipper.featureOn("webhook") &&
      NOTIFICATION_IDS.WEBHOOK_ERRORS,
    featureFlipper.featureOn("webhook_connected_content_alerts") && NOTIFICATION_IDS.CONNECTED_CONTENT_ERRORS,
  ].filter(isNumber),
  [t("comments", "Comments")]: [
    featureFlipper.featureOn("canvas_comments_notifications") && NOTIFICATION_IDS.CANVAS_COMMENTS,
  ].filter(isNumber),
  [t("news-feed-cards", "News Feed Cards")]: [
    featureFlipper.featureOn("news_feed") && NOTIFICATION_IDS.NEWS_FEED_CARD_LIVE,
  ].filter(isNumber),
  [t("analytics-and-reports", "Analytics & Reports")]: [NOTIFICATION_IDS.WEEKLY_ANALYTICS_REPORT],
};

function isNumber<T extends number>(value: T | boolean): value is T {
  return typeof value === "number";
}

const NOTIFICATION_CATEGORIES = Object.keys(NOTIFICTION_CATEGORIES_BASE)
  .sort()
  .filter((key) => NOTIFICTION_CATEGORIES_BASE[key] && NOTIFICTION_CATEGORIES_BASE[key].length > 0)
  .reduce((cur, key) => {
    return Object.assign(cur, {
      [key]: NOTIFICTION_CATEGORIES_BASE[key].sort((a, b) => (IDS_TO_NAMES[a] > IDS_TO_NAMES[b] ? 1 : -1)),
    });
  }, {});

const notificationConstants = {
  ALL_ADMINS_TAG: t("note-constants-all-admins", "All Admins"),
  ALL_DEVS_TAG: t("note-constants-all-users", "All Dashboard Users"),
  ALL_APP_GROUPS_TAG: t("note-constants-all-workspaces", "All Workspaces"),
  // TODO (Gabe) - remove non-parsley regex validation after updating select2 to 4.0.6-rc.0
  EMAIL_REGEX:
    // eslint-disable-next-line no-control-regex
    /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,

  NON_ENTERPRISE_NOTIFICATION_CATEGORIES: ["Account Administration"],
  NOTIFICATION_IDS,
  NOTIFICATIONS_WITH_ALL_TAGS_ALLOWED,
  IDS_TO_TYPES,
  IDS_TO_HELPER_TEXT,
  IDS_TO_NAMES,
  NOTIFICATION_CATEGORIES,
  SDK_FALLBACK_ANDROID_NOTIFICATION_CHANNEL_ID: "com_appboy_default_notification_channel",
};

export default notificationConstants;
