/**
 * @fileoverview this is the typed version of channelLabelGetter, and uses the
 *   MessageType union instead of the English label to identify the i18n string
 * @owner @Appboy/channels
 */

import type { MessageType } from "@lib/campaignMessageTypeConstants";
import type { I18nT } from "@lib/dashboardI18n";

const messageTypesGetter = (t: I18nT<"channel-labels">): { [k in MessageType]: string } => ({
  androidPush: t("android-push-group", "Android Push"),
  banner: t("banner", "Banner"),
  contentCard: t("content-cards-stats-group", "Content Cards"),
  control: t("control", "Control"),
  email: t("email-stats-group", "Email"),
  featureFlag: t("feature-flag", "Feature Flags"),
  featureFlagExperiment: t("feature-flag-experiment", "Feature Flag Experiments"),
  iosPush: t("ios-push", "iOS Push"),
  kindlePush: t("kindle-push", "Kindle Push"),
  line: t("line", "LINE"),
  push: t("push-stats-group", "Push"),
  slideup: t("slide-up", "Original In-App Message"),
  sms: t("sms-stats-group", "SMS"),
  trigger: t("iam-stats-group", "In-App Messages"),
  webhook: t("webhook", "Webhook"),
  webPush: t("web-push", "Web Push"),
  whatsApp: t("whatsapp", "WhatsApp"),
});

export default messageTypesGetter;
