/**
 * @owner @Appboy/messaging-and-automation
 */

import type { I18nT } from "@lib/dashboardI18n";

export type ActionLabelsTrans = I18nT<"action-labels">;

const actionLabelsGetter = (t: I18nT<"action-labels">) => {
  return {
    Add: t("add", "Add"),
    Archive: t("archive", "Archive"),
    Unarchive: t("unarchive", "Unarchive"),
    Cancel: t("cancel", "Cancel"),
    Continue: t("continue", "Continue"),
    Export: t("export", "Export"),
    Save: t("save", "Save"),
    Revert: t("revert", "Revert"),
    More: t("more", "More"),
    Hide: t("hide", "Hide"),
    Close: t("close", "Close"),
    Done: t("done", "Done"),
    Edit: t("edit", "Edit"),
    Delete: t("delete", "Delete"),
    Discard: t("discard", "Discard"),
    Duplicate: t("duplicate", "Duplicate"),
    Confirm: t("confirm", "Confirm"),
    Update: t("update", "Update"),
    Undo: t("undo", "Undo"),
    Okay: t("okay", "Okay"),
    Remove: t("remove", "Remove"),
    Refresh: t("refresh", "Refresh"),
    Search: t("search", "Search"),
    Exit: t("exit", "Exit"),
    Generate: t("generate", "Generate"),
    Restore: t("restore-interaction-data", "Restore Interaction Data"),
    "Apply tags": t("apply-tags", "Apply tags"),
    "Tag as": t("tag-as", "Tag as"),
    "Update name": t("update-name", "Update name"),
    "Got it": t("got-it", "Got it"),
    "Show Rule": t("show-rule", "Show Rule"),
    "Don't Save": t("do-not-save", "Don't Save"),
    "Keep Editing": t("keep-editing", "Keep Editing"),
    "Add Description": t("add-description", "Add Description"),
    "Remove Description": t("remove-description", "Remove Description"),
    "Select All": t("select-all", "Select All"),
    "Continue Anyway": t("continue-anyway", "Continue Anyway"),
    "Try Again": t("try-again", "Try Again"),
    "Save and continue": t("save-and-continue", "Save and continue"),
    "Save as draft": t("save-as-draft", "Save as draft"),
    "Update Canvas": t("update-canvas", "Update Canvas"),
  };
};

export type ActionLabelsOptions = keyof ReturnType<typeof actionLabelsGetter>;

export default actionLabelsGetter;
